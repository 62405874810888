import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "../components/scss/app.scss"

const AboutKalotiPage = () => (
    <Layout>
    <SEO title="Composite Bonding | Teeth Bonding | Top Cosmetic Dentist" 
    description="Composite resin bonding is a fast, minimally invasive and inexpensive option to get the beautiful smile you\'re looking for. Book your Free Consult at The Smile Stylist." 
    keywords="Teeth bonding, Composite Bonding" />
    <main className="site-content" role="main">
    <section id="main-top-img" className="in-office-bonding hidden-xs">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 sec-title wow animated fadeInDown">
            <h1>In-Office Bonding</h1>
            <h2>It can be a good option for the beautiful smile you're looking for</h2>
          </div>
        </div>
      </div>
    </section>
    <section id="main-top-img" className="in-office-bonding hidden-sm hidden-md hidden-lg">
      <div className="container">
        <div className="row">
          <p>&nbsp;</p>
        </div>
      </div>
    </section>
    <section id="top-mobile" className="hidden-sm hidden-md hidden-lg">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 sec-title wow animated fadeInDown">
            <h2>In-Office Bonding</h2>
            <h2>It can be a good option for the beautiful smile you're looking for</h2>
          </div>
        </div>
      </div>
    </section>
    <section id="main-white">
      <div className="container">
        <div className="row">
          <div className="col-sm-8 wow animated fadeInRight">
            <h2>Full Mouth</h2>
            <p>Composite resin bonding can be a fast, minimally invasive and inexpensive option for the beautiful smile you're looking for. But knowing what makes you a good candidate can help you determine if it's the right investment for you.</p>
            <h2>What Is Composite Bonding?</h2>
            <p>Composite bonding is a cosmetic technique wherein a type of dental material - in this case, composite resin - is shaped and molded on your teeth to give the appearance of straighter, whiter smile. It can be used as a cosmetic solution to chipped teeth, gapped teeth and staining in both teeth and fillings. Unlike <a href="/porcelain-veneers">porcelain veneer</a> placement, which can take more than two visits, composite resin bonding can be completed in one appointment, and often without even dental anesthesia!</p>
            <h2>What Can You Expect During Your Visit?</h2>
            <p>Composite bonding is a safe and effective technique that was developed more than 50 years ago, and has been widely available for over three decades. The latest breakthroughs in technology and materials such as nano bonding today are very efficient, make this procedure more exciting than ever.</p>
            <h2>How its actually done?</h2>
            <p>The treatment itself often starts with the removal of some surface enamel, allowing us to best shape the composite resin to your tooth, followed by the application of the bonding agent. We will then add the composite resin, cure it with a special light and finish by polishing your teeth. Because the process involves a high level of technique for a natural mold and shape, it's best to work with a dentist who has experience and specialty in this area. Dr. Kaloti has extensive experience with resin bondings and achieves beautiful results for some of his most discerning patients.</p>
            <p><a className="btn btn-red" href="/contact">Book Your Appointment</a></p>
          </div>
          <div className="col-sm-4 wow animated zoomIn text-center service__shadow-imgs" data-wow-delay="0.4s">
            <p>
              <img src={ require("../images/desktop/services/Bonding.jpg" )}alt="Dental Bonding" className="img-responsive" />
            </p>

            <p>
              <img src={ require("../images/desktop/services/Bonding2.jpg" )}alt="In-office Bonding Before and After" className="img-responsive" />
            </p>

            <p>
              <img src={ require("../images/desktop/services/Before After Veneers 11.png" )}alt="Dental Bonding Before and After" className="img-responsive" />
            </p>
          </div>
        </div>
      </div>
    </section>
  </main>
  </Layout>
)

export default AboutKalotiPage